import TcalVsVoyageFormProperties from './TcalVsVoyageFormProperties'
import TcalVsVoyageApiUrls from 'src/apiUrls/TcalVsVoyageApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngEditForm }
} = components

function TcalVsVoyageEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {
    //do nothing
   }
}) {
  return (
    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <TcalVsVoyageFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={TcalVsVoyageFormProperties.formikProps}
      toClientDataFormat={TcalVsVoyageFormProperties.toClientDataFormat}
      toServerDataFormat={TcalVsVoyageFormProperties.toServerDataFormat}
      fetch={{
        url: TcalVsVoyageApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: TcalVsVoyageApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default TcalVsVoyageEditForm
