import TcalVsVoyageFormProperties from './TcalVsVoyageFormProperties'
import TcalVsVoyageApiUrls from 'src/apiUrls/TcalVsVoyageApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngViewForm }
} = components

function TcalVsVoyageViewForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {
    //do nothing
   }
}) {
  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <TcalVsVoyageFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
        />
      }
      formikProps={TcalVsVoyageFormProperties.formikProps}
      toClientDataFormat={TcalVsVoyageFormProperties.toClientDataFormat}
      toServerDataFormat={TcalVsVoyageFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: TcalVsVoyageApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
    />
  )
}

export default TcalVsVoyageViewForm
