import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import TcalVsVoyageViewForm from './TcalVsVoyageViewForm'
import VoyageLegCrudTable from './VoyageLegCrudTable'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import TcalVsVoyageKeys from 'src/constants/locale/key/TcalVsVoyage'

const { CngTabs } = components


function ViewPage({ showNotification }) {
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.TCAL_VS_VOYAGE])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let tcalVsVoyage = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.TITLE
    )
    let voyageLeg = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.TITLE
    )

    return {
      tcalVsVoyage,
      voyageLeg
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <TcalVsVoyageViewForm
              showNotification={showNotification}
              id={id}
              onFetchPreSuccess={(datum) => {
                //do nothing
              }}
            />
          </Container>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.tcalVsVoyage,
              tabContent: (
                <TcalVsVoyageViewForm
                  showNotification={showNotification}
                  id={id}
                />
              )
            },
            {
              tabName: translatedTextsObject.voyageLeg,
              tabContent: (
                <VoyageLegCrudTable
                  showNotification={showNotification}
                  tcalVsVoyageId={id}
                  isViewOnly={true}
                />
              )
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default ViewPage