import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import TcalVsVoyageApiUrls from 'src/apiUrls/TcalVsVoyageApiUrls'
import React from 'react'
import TcalVsVoyageKeys from 'src/constants/locale/key/TcalVsVoyage'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { SimpleCardHeader: CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.TCAL_VS_VOYAGE
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let tcalVsVoyage = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: tcalVsVoyage
      }
    )
    let voyageId = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VOYAGE_ID
    )
    let createdBy = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.UPDATED_DATE
    )
    let version = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VERSION
    )
    let remarks = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.REMARKS
    )
    let service = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.SERVICE
    )
    let voyageNo = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VOYAGE_NO
    )
    let vesselId = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VESSEL_ID
    )
    let partyId = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.PARTY_ID
    )
    let scheduleDocId = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.SCHEDULE_DOC_ID
    )
    let scheduleNo = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.SCHEDULE_NO
    )
    let voyageStatus = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VOYAGE_STATUS
    )
    let intVoyageNo = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.INT_VOYAGE_NO
    )
    let portPair = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.PORT_PAIR
    )
    let routeId = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.ROUTE_ID
    )
    let transitTime = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.TRANSIT_TIME
    )

    return {
      tcalVsVoyage,
      tableTitle,
      voyageId,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      version,
      remarks,
      service,
      voyageNo,
      vesselId,
      partyId,
      scheduleDocId,
      scheduleNo,
      voyageStatus,
      intVoyageNo,
      portPair,
      routeId,
      transitTime
    }
  }

  const columns = [
    // {
    //   field: 'voyageId',
    //   title: translatedTextsObject.voyageId,
    //   type: 'numeric',
    // },
    // {
    //   field: 'createdBy',
    //   title: translatedTextsObject.createdBy,
    //   type: 'numeric',
    // },
    // {
    //   field: 'createdDate',
    //   title: translatedTextsObject.createdDate,
    //   type: 'date',
    //   filterComponent: CngDateRangeFilter,
    // },
    // {
    //   field: 'updatedBy',
    //   title: translatedTextsObject.updatedBy,
    //   type: 'numeric',
    // },
    // {
    //   field: 'updatedDate',
    //   title: translatedTextsObject.updatedDate,
    //   type: 'date',
    //   filterComponent: CngDateRangeFilter,
    // },
    // {
    //   field: 'version',
    //   title: translatedTextsObject.version,
    //   type: 'numeric',
    // },
    {
      field: 'vesselId',
      title: translatedTextsObject.vesselId,
      type: 'numeric',
    },
    {
      field: 'voyageNo',
      title: translatedTextsObject.voyageNo,
    },
    {
      field: 'intVoyageNo',
      title: translatedTextsObject.intVoyageNo,
    },
    {
      field: 'remarks',
      title: translatedTextsObject.remarks,
    },
    {
      field: 'service',
      title: translatedTextsObject.service,
    },
    {
      field: 'scheduleNo',
      title: translatedTextsObject.scheduleNo,
    },
    {
      field: 'voyageStatus',
      title: translatedTextsObject.voyageStatus,
    },
    // {
    //   field: 'partyId',
    //   title: translatedTextsObject.partyId,
    //   type: 'numeric',
    // },
    // {
    //   field: 'scheduleDocId',
    //   title: translatedTextsObject.scheduleDocId,
    //   type: 'numeric',
    // },
    // {
    //   field: 'portPair',
    //   title: translatedTextsObject.portPair,
    // },
    // {
    //   field: 'routeId',
    //   title: translatedTextsObject.routeId,
    //   type: 'numeric',
    // },
    // {
    //   field: 'transitTime',
    //   title: translatedTextsObject.transitTime,
    //   type: 'numeric',
    // }
  ];

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: TcalVsVoyageApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: TcalVsVoyageApiUrls.EXPORT }}
              fetch={{ url: TcalVsVoyageApiUrls.GET }}
              idAccessor='id'
              notification={notification}
              //tableConfigurationCode='REPLACE_WITH_CORE_TABLE_CONFIG_CODE'
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default TablePage
