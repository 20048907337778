import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import TcalVsVoyageViewForm from './TcalVsVoyageViewForm'
import TcalVsVoyageAddForm from './TcalVsVoyageAddForm'
import TcalVsVoyageEditForm from './TcalVsVoyageEditForm'
import VoyageLegCrudTable from './VoyageLegCrudTable'
import Namespace from 'src/constants/locale/Namespace'
import TcalVsVoyageKeys from 'src/constants/locale/key/TcalVsVoyage'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [tcalVsVoyageId, setTcalVsVoyageId] = useState('')
  const { translate } = useTranslation([
    Namespace.TCAL_VS_VOYAGE,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let tcalVsVoyage = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.TITLE
    )
    let voyageLeg = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: tcalVsVoyage
      }
    )

    return {
      tcalVsVoyage,
      voyageLeg,
      tabLockedMessage
    }
  }

  function makeTcalVsVoyageForm() {
    if (tcalVsVoyageId === '') {
      return (
        <TcalVsVoyageAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => {
            setTcalVsVoyageId(datum.id)
            setLockedOnFirstTab(false)
          }}
        />
      )
    } else {
      return (
        <TcalVsVoyageEditForm
          showNotification={showNotification}
          id={tcalVsVoyageId}
        />
      )
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <TcalVsVoyageViewForm
              showNotification={showNotification}
              id={tcalVsVoyageId}
            />
          </Container>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.tcalVsVoyage,
              tabContent: makeTcalVsVoyageForm()
            },
            {
              tabName: translatedTextsObject.voyageLeg,
              tabContent: (
                <VoyageLegCrudTable
                  showNotification={showNotification}
                  tcalVsVoyageId={tcalVsVoyageId}
                />
              )
            },
          ]}
          lockedOnFirstTab={lockedOnFirstTab}
          tabLockedMessage={translatedTextsObject.tabLockedMessage}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage