import TcalVsVoyageFormProperties from './TcalVsVoyageFormProperties'
import TcalVsVoyageApiUrls from 'src/apiUrls/TcalVsVoyageApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngAddForm }
} = components

function TcalVsVoyageAddForm({
  history,
  showNotification,
  onPostSubmitSuccess
}) {
  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <TcalVsVoyageFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={TcalVsVoyageFormProperties.formikProps}
      toClientDataFormat={TcalVsVoyageFormProperties.toClientDataFormat}
      toServerDataFormat={TcalVsVoyageFormProperties.toServerDataFormat}
      create={{
        url: TcalVsVoyageApiUrls.POST,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
    />
  )
}

export default TcalVsVoyageAddForm
