import makeValidationSchema from './TcalVsVoyageMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import TcalVsVoyageKeys from 'src/constants/locale/key/TcalVsVoyage'
import { Grid } from '@material-ui/core'
import {
  components,
  DateTimeFormatter,
} from 'cng-web-lib'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  voyageId: 0,
  createdBy: 0,
  createdDate: "",
  updatedBy: 0,
  updatedDate: "",
  version: 0,
  remarks: "",
  service: "",
  voyageNo: "",
  vesselId: 0,
  partyId: 0,
  scheduleDocId: 0,
  scheduleNo: "",
  voyageStatus: "",
  intVoyageNo: "",
  portPair: "",
  routeId: 0,
  transitTime: 0,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.TCAL_VS_VOYAGE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let voyageId = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VOYAGE_ID
    )
    let createdBy = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.UPDATED_DATE
    )
    let version = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VERSION
    )
    let remarks = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.REMARKS
    )
    let service = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.SERVICE
    )
    let voyageNo = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VOYAGE_NO
    )
    let vesselId = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VESSEL_ID
    )
    let partyId = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.PARTY_ID
    )
    let scheduleDocId = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.SCHEDULE_DOC_ID
    )
    let scheduleNo = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.SCHEDULE_NO
    )
    let voyageStatus = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VOYAGE_STATUS
    )
    let intVoyageNo = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.INT_VOYAGE_NO
    )
    let portPair = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.PORT_PAIR
    )
    let routeId = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.ROUTE_ID
    )
    let transitTime = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.TRANSIT_TIME
    )

    return {
      voyageId,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      version,
      remarks,
      service,
      voyageNo,
      vesselId,
      partyId,
      scheduleDocId,
      scheduleNo,
      voyageStatus,
      intVoyageNo,
      portPair,
      routeId,
      transitTime
    }
  }

  return (
    <Grid container spacing={3}>
      {/* <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageId}>
        <CngTextField
          name="voyageId"
          type="number"
          label={translatedTextsObject.voyageId}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.createdBy}>
        <CngTextField
          name="createdBy"
          type="number"
          label={translatedTextsObject.createdBy}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.createdDate}>
        <CngDateField
          name="createdDate"
          label={translatedTextsObject.createdDate}
          timeFormat={false}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.updatedBy}>
        <CngTextField
          name="updatedBy"
          type="number"
          label={translatedTextsObject.updatedBy}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.updatedDate}>
        <CngDateField
          name="updatedDate"
          label={translatedTextsObject.updatedDate}
          timeFormat={false}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.version}>
        <CngTextField
          name="version"
          type="number"
          label={translatedTextsObject.version}
          disabled={disabled}
        />
      </CngGridItem> */}
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vesselId}>
        <CngTextField
          name="vesselId"
          type="number"
          label={translatedTextsObject.vesselId}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageNo}>
        <CngTextField
          name="voyageNo"
          label={translatedTextsObject.voyageNo}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.intVoyageNo}>
        <CngTextField
          name="intVoyageNo"
          label={translatedTextsObject.intVoyageNo}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.remarks}>
        <CngTextField
          name="remarks"
          label={translatedTextsObject.remarks}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.service}>
        <CngTextField
          name="service"
          label={translatedTextsObject.service}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduleNo}>
        <CngTextField
          name="scheduleNo"
          label={translatedTextsObject.scheduleNo}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageStatus}>
        <CngTextField
          name="voyageStatus"
          label={translatedTextsObject.voyageStatus}
          disabled={disabled}
        />
      </CngGridItem>
      {/* <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
        <CngTextField
          name="partyId"
          type="number"
          label={translatedTextsObject.partyId}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduleDocId}>
        <CngTextField
          name="scheduleDocId"
          type="number"
          label={translatedTextsObject.scheduleDocId}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portPair}>
        <CngTextField
          name="portPair"
          label={translatedTextsObject.portPair}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.routeId}>
        <CngTextField
          name="routeId"
          type="number"
          label={translatedTextsObject.routeId}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.transitTime}>
        <CngTextField
          name="transitTime"
          type="number"
          label={translatedTextsObject.transitTime}
          disabled={disabled}
        />
      </CngGridItem> */}
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  localData.createdDate = DateTimeFormatter.toClientDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toClientDate(
    localData.updatedDate
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.createdDate = DateTimeFormatter.toServerDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toServerDate(
    localData.updatedDate
  );
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
