import makeValidationSchema from './VoyageLegMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import TcalVsVoyageApiUrls from 'src/apiUrls/TcalVsVoyageApiUrls'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import TcalVsVoyageKeys from 'src/constants/locale/key/TcalVsVoyage'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  voyageLegId: 0,
  createdBy: 0,
  createdDate: "",
  updatedBy: 0,
  updatedDate: "",
  version: 0,
  arrivalTime: "",
  departureTime: "",
  portCode: "",
  seqNo: 0,
  partyId: 0,
  terminalCode: "",
  transportMode: "",
  schVesselName: "",
  schVoyageNo: "",
  schService: "",
  transitTime: 0,
  source: "",
  cyCutoffTime: 0,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.TCAL_VS_VOYAGE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let voyageLeg = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.TITLE
    )
    let voyageLegId = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.VOYAGE_LEG_ID
    )
    let createdBy = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.CREATED_BY
    )
    let createdDate = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.UPDATED_DATE
    )
    let version = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.VERSION
    )
    let arrivalTime = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.ARRIVAL_TIME
    )
    let departureTime = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.DEPARTURE_TIME
    )
    let portCode = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.PORT_CODE
    )
    let seqNo = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.SEQ_NO
    )
    let partyId = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.PARTY_ID
    )
    let terminalCode = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.TERMINAL_CODE
    )
    let transportMode = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.TRANSPORT_MODE
    )
    let schVesselName = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.SCH_VESSEL_NAME
    )
    let schVoyageNo = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.SCH_VOYAGE_NO
    )
    let schService = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.SCH_SERVICE
    )
    let transitTime = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.TRANSIT_TIME
    )
    let source = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.SOURCE
    )
    let cyCutoffTime = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.CY_CUTOFF_TIME
    )

    return {
      voyageLeg,
      voyageLegId,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      version,
      arrivalTime,
      departureTime,
      portCode,
      seqNo,
      partyId,
      terminalCode,
      transportMode,
      schVesselName,
      schVoyageNo,
      schService,
      transitTime,
      source,
      cyCutoffTime
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.voyageLeg} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageLegId}>
            <CngTextField
              name="voyageLegId"
              type="number"
              label={translatedTextsObject.voyageLegId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.createdBy}>
            <CngTextField
              name="createdBy"
              type="number"
              label={translatedTextsObject.createdBy}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.createdDate}>
            <CngDateField
              name="createdDate"
              label={translatedTextsObject.createdDate}
              timeFormat={false}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.updatedBy}>
            <CngTextField
              name="updatedBy"
              type="number"
              label={translatedTextsObject.updatedBy}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.updatedDate}>
            <CngDateField
              name="updatedDate"
              label={translatedTextsObject.updatedDate}
              timeFormat={false}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.version}>
            <CngTextField
              name="version"
              type="number"
              label={translatedTextsObject.version}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.arrivalTime}>
            <CngDateField
              name="arrivalTime"
              label={translatedTextsObject.arrivalTime}
              timeFormat={false}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.departureTime}>
            <CngDateField
              name="departureTime"
              label={translatedTextsObject.departureTime}
              timeFormat={false}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portCode}>
            <CngTextField
              name="portCode"
              label={translatedTextsObject.portCode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.seqNo}>
            <CngTextField
              name="seqNo"
              type="number"
              label={translatedTextsObject.seqNo}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
            <CngTextField
              name="partyId"
              type="number"
              label={translatedTextsObject.partyId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.terminalCode}>
            <CngTextField
              name="terminalCode"
              label={translatedTextsObject.terminalCode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.transportMode}>
            <CngTextField
              name="transportMode"
              label={translatedTextsObject.transportMode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.schVesselName}>
            <CngTextField
              name="schVesselName"
              label={translatedTextsObject.schVesselName}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.schVoyageNo}>
            <CngTextField
              name="schVoyageNo"
              label={translatedTextsObject.schVoyageNo}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.schService}>
            <CngTextField
              name="schService"
              label={translatedTextsObject.schService}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.transitTime}>
            <CngTextField
              name="transitTime"
              type="number"
              label={translatedTextsObject.transitTime}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.source}>
            <CngTextField
              name="source"
              label={translatedTextsObject.source}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cyCutoffTime}>
            <CngTextField
              name="cyCutoffTime"
              type="number"
              label={translatedTextsObject.cyCutoffTime}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const VoyageLegFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default VoyageLegFormProperties
