import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SubscriptionKeys from 'src/constants/locale/key/Subscription'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngSwitchField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  subscriptionCode: "",
  subscriptionName: "",
  subscriptionDescription: "",
  status: true,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.SUBSCRIPTION)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let subscription = translate(
      Namespace.SUBSCRIPTION,
      SubscriptionKeys.TITLE
    )
    let subscriptionCode = translate(
      Namespace.SUBSCRIPTION,
      SubscriptionKeys.SUBSCRIPTION_CODE
    )
    let subscriptionName = translate(
      Namespace.SUBSCRIPTION,
      SubscriptionKeys.SUBSCRIPTION_NAME
    )
    let subscriptionDescription = translate(
      Namespace.SUBSCRIPTION,
      SubscriptionKeys.SUBSCRIPTION_DESCRIPTION
    )
    let status = translate(
      Namespace.SUBSCRIPTION,
      SubscriptionKeys.STATUS
    )

    return {
      subscription,
      subscriptionCode,
      subscriptionName,
      subscriptionDescription,
      status
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.subscription} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.subscriptionCode}>
            <CngTextField
              name="subscriptionCode"
              label={translatedTextsObject.subscriptionCode + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.subscriptionName}>
            <CngTextField
              name="subscriptionName"
              label={translatedTextsObject.subscriptionName + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.subscriptionDescription}>
            <CngTextField
              name="subscriptionDescription"
              label={translatedTextsObject.subscriptionDescription + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.status}>
            <CngSwitchField
                name="status"
                label={translatedTextsObject.status}
                disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
