import {
  components,
  useTranslation
} from 'cng-web-lib'

import VoyageLegFormProperties from './VoyageLegFormProperties'
import VoyageLegApiUrls from 'src/apiUrls/VoyageLegApiUrls'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import TcalVsVoyageKeys from 'src/constants/locale/key/TcalVsVoyage'
import {
  DateTimeFormatter,
} from 'cng-web-lib'


const {
  table: {
    DateRangeFilter: CngDateRangeFilter,
    CngServerModeDialogFormTable,
  }
} = components


function VoyageLegCrudTable({
  showNotification,
  tcalVsVoyageId,
  isViewOnly = false
}) {
  const { translate } = useTranslation([Namespace.TCAL_VS_VOYAGE])
  const translatedTextsObject = makeTranslatedTextsObject()

  let create = { url: VoyageLegApiUrls.POST }
  let update = { url: VoyageLegApiUrls.PUT }
  let del = { url: VoyageLegApiUrls.DELETE }
  if (isViewOnly) {
    create = undefined
    update = undefined
    del = undefined
  }

  function makeTranslatedTextsObject() {
    let voyageLegId = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.VOYAGE_LEG_ID
    )
    let createdBy = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.CREATED_BY
    )
    let createdDate = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.UPDATED_DATE
    )
    let version = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.VERSION
    )
    let arrivalTime = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.ARRIVAL_TIME
    )
    let departureTime = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.DEPARTURE_TIME
    )
    let portCode = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.PORT_CODE
    )
    let seqNo = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.SEQ_NO
    )
    let partyId = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.PARTY_ID
    )
    let terminalCode = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.TERMINAL_CODE
    )
    let transportMode = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.TRANSPORT_MODE
    )
    let schVesselName = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.SCH_VESSEL_NAME
    )
    let schVoyageNo = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.SCH_VOYAGE_NO
    )
    let schService = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.SCH_SERVICE
    )
    let transitTime = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.TRANSIT_TIME
    )
    let source = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.SOURCE
    )
    let cyCutoffTime = translate(
      Namespace.TCAL_VS_VOYAGE,
      TcalVsVoyageKeys.VoyageLeg.CY_CUTOFF_TIME
    )

    return {
      voyageLegId,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      version,
      arrivalTime,
      departureTime,
      portCode,
      seqNo,
      partyId,
      terminalCode,
      transportMode,
      schVesselName,
      schVoyageNo,
      schService,
      transitTime,
      source,
      cyCutoffTime
    }
  }

  const columns = [
    {
      field: 'voyageLegId',
      title: translatedTextsObject.voyageLegId,
      type: 'numeric',
    },
    {
      field: 'createdBy',
      title: translatedTextsObject.createdBy,
      type: 'numeric',
    },
    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      type: 'date',
      filterComponent: CngDateRangeFilter,
    },
    {
      field: 'updatedBy',
      title: translatedTextsObject.updatedBy,
      type: 'numeric',
    },
    {
      field: 'updatedDate',
      title: translatedTextsObject.updatedDate,
      type: 'date',
      filterComponent: CngDateRangeFilter,
    },
    {
      field: 'version',
      title: translatedTextsObject.version,
      type: 'numeric',
    },
    {
      field: 'arrivalTime',
      title: translatedTextsObject.arrivalTime,
      type: 'date',
      filterComponent: CngDateRangeFilter,
    },
    {
      field: 'departureTime',
      title: translatedTextsObject.departureTime,
      type: 'date',
      filterComponent: CngDateRangeFilter,
    },
    {
      field: 'portCode',
      title: translatedTextsObject.portCode,
    },
    {
      field: 'seqNo',
      title: translatedTextsObject.seqNo,
      type: 'numeric',
    },
    {
      field: 'partyId',
      title: translatedTextsObject.partyId,
      type: 'numeric',
    },
    {
      field: 'terminalCode',
      title: translatedTextsObject.terminalCode,
    },
    {
      field: 'transportMode',
      title: translatedTextsObject.transportMode,
    },
    {
      field: 'schVesselName',
      title: translatedTextsObject.schVesselName,
    },
    {
      field: 'schVoyageNo',
      title: translatedTextsObject.schVoyageNo,
    },
    {
      field: 'schService',
      title: translatedTextsObject.schService,
    },
    {
      field: 'transitTime',
      title: translatedTextsObject.transitTime,
      type: 'numeric',
    },
    {
      field: 'source',
      title: translatedTextsObject.source,
    },
    {
      field: 'cyCutoffTime',
      title: translatedTextsObject.cyCutoffTime,
      type: 'numeric',
    }
  ]

  return (
    <CngServerModeDialogFormTable
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      disabled={isViewOnly}
      showNotification={showNotification}
      tableColumns={columns}
      formProperties={{
        ...VoyageLegFormProperties,
        formikProps: {
          ...VoyageLegFormProperties.formikProps,
          initialValues: {
            ...VoyageLegFormProperties.formikProps.initialValues,
            tcalVsVoyageId: tcalVsVoyageId
          }
        }
      }}
      toClientDataFormat={(serverData) => {
        serverData.forEach((datum) => {
          datum.createdDate = DateTimeFormatter.toClientDate(
            datum.createdDate
          );
          datum.updatedDate = DateTimeFormatter.toClientDate(
            datum.updatedDate
          );
          datum.arrivalTime = DateTimeFormatter.toClientDate(
            datum.arrivalTime
          );
          datum.departureTime = DateTimeFormatter.toClientDate(
            datum.departureTime
          );
        });
        return serverData;
      }}
      toServerDataFormat={(localDatum) => {
        localDatum.createdDate = DateTimeFormatter.toServerDate(
          localDatum.createdDate
        );
        localDatum.updatedDate = DateTimeFormatter.toServerDate(
          localDatum.updatedDate
        );
        localDatum.arrivalTime = DateTimeFormatter.toServerDate(
          localDatum.arrivalTime
        );
        localDatum.departureTime = DateTimeFormatter.toServerDate(
          localDatum.departureTime
        );
        return localDatum;
      }}
      fetch={{
        url: VoyageLegApiUrls.GET,
        body: {
          tcalVsVoyageId: tcalVsVoyageId
        }
      }}
      create={create}
      update={update}
      del={del}
      idAccessor='id'
    // tableConfigurationCode=''
    />
  )
}

export default VoyageLegCrudTable
